<template>
  <div class="flavors" :style="{backgroundImage:`url(${fruit})` }">
    <div class="flavor-list-container"
         @mouseleave="startAutoPlay">
      <button class="prev-button" @click="prevSlide">&lt;</button>
      <div class="flavor-list" ref="flavorList">
        <div v-for="flavor in flavors" :key="flavor.name"
             class="flavor" @click="goDetail(flavor.name)">
          <img :src="flavor.image" :alt="flavor.name"/>
          <p id="content" class="name" :style="{backgroundColor:flavor.bg}" v-html="flavor.name"></p>
        </div>
      </div>
      <button class="next-button" @click="nextSlide">&gt;</button>
    </div>

  </div>
</template>

<script>
export default {
  name: "FlavorsPage",
  props: {
    fruit: {
      type: String,
      default: () => require("@/assets/1fr.jpg"),
    },
  },
  data() {
    return {
      flavors: [
        {
          name: "TROPICAL LEMONADE",
          image: require("@/assets/1.png"),
          bg: "rgb(122,195,226)",
          fruit: require("@/assets/1fr.jpg"),
        },
        {
          name: "LUSCIOUS LYCHEE",
          image: require("@/assets/2.png"),
          bg: "rgb(220,157,140)",
          fruit: require("@/assets/2fr.jpg"),
        },
        {
          name: "DIRTY SPRITE",
          image: require("@/assets/3.png"),
          bg: "rgb(194,191,221)",
          fruit: require("@/assets/3fr.jpg"),
        },
        {
          name: "STRAWBERRY COUGH",
          image: require("@/assets/4.png"),
          bg: "rgb(167,61,62)",
          fruit: require("@/assets/4fr.jpg"),
        },
        {
          name: "BLACKBERRY OCTANE",
          image: require("@/assets/5.png"),
          bg: "rgb(96,83,142)",
          fruit: require("@/assets/5fr.jpg"),
        },
        {
          name: "PINEAPPLE EXPRESS",
          image: require("@/assets/6.png"),
          bg: "rgb(252,249,202)",
          fruit: require("@/assets/6fr.jpg"),
        },
        {
          name: "DEW NECTAR",
          image: require("@/assets/7.png"),
          bg: "rgb(155,198,107)",
          fruit: require("@/assets/7fr.jpg"),
        },
        {
          name: "MANGO MINTALITY",
          image: require("@/assets/8.png"),
          bg: "rgb(119,188,93)",
          fruit: require("@/assets/8fr.jpg"),
        },
        {
          name: "CAPRI SUN",
          image: require("@/assets/9.png"),
          bg: "rgb(196,238,236)",
          fruit: require("@/assets/9fr.jpg"),
        },
        {
          name: "PLUM PARADISE",
          image: require("@/assets/10.png"),
          bg: "rgb(139,56,84)",
          fruit: require("@/assets/10fr.jpg"),
        },
      ],
      currentIndex: 0, // Current visible index
      itemWidth: 150,  // Width of each flavor item (adjustable)
      autoPlayInterval: null, // 自动播放定时器
    };
  },
  mounted() {
    this.startAutoPlay();
  },
  beforeDestroy() {
    this.stopAutoPlay();
  },
  methods: {
    formatName(name) {
      return name.split(' ').join('<br>'); // 将空格替换为换行符
    },
    goDetail(name) {
      const encodedName = encodeURIComponent(name);
      const basePath = window.location.origin; // 获取当前域名和端口
      const fullPath = `${basePath}/detail/${encodedName}`; // 构建完整路径
      window.location.href = fullPath; // 跳转到新页面
    },

    nextSlide() {
      const container = this.$refs.flavorList;
      if (container == null) {
        return
      }
      const maxScroll = container.scrollWidth - container.clientWidth;
      // 当前滚动位置加上一个项的宽度
      const newScrollLeft = container.scrollLeft + this.itemWidth;

      // 如果超过最大滚动值，重置到起点（循环播放）
      if (newScrollLeft > maxScroll) {
        container.scrollLeft = 0;
      } else {
        container.scrollLeft = newScrollLeft;
      }
    },
    prevSlide() {
      const container = this.$refs.flavorList;
      if (container == null) {
        return
      }
      // 当前滚动位置减去一个项的宽度
      const newScrollLeft = container.scrollLeft - this.itemWidth;

      // 如果小于 0（到达开头），跳到末尾（循环播放）
      if (newScrollLeft < 0) {
        container.scrollLeft = container.scrollWidth - container.clientWidth;
      } else {
        container.scrollLeft = newScrollLeft;
      }
    },
    startAutoPlay() {
      const container = this.$refs.flavorList;
      if (!container || this.autoPlayInterval) return;

      // 每隔 20ms 滚动 1px，模拟平滑移动
      this.autoPlayInterval = setInterval(() => {
        const maxScroll = container.scrollWidth - container.clientWidth;

        // 如果到达滚动的最右侧，回到起始位置
        if (container.scrollLeft >= maxScroll) {
          container.scrollLeft = 0;
        } else {
          container.scrollLeft += 1; // 缓慢滚动
        }
      }, 30); // 每 20 毫秒滚动一次
    },
    stopAutoPlay() {
      if (this.autoPlayInterval) {
        clearInterval(this.autoPlayInterval);
        this.autoPlayInterval = null;
      }
    },
  },
};
</script>

<style scoped>
.flavors {
  /*max-width: 450px;*/
  display: flex;
  overflow: hidden; /* Hide overflowing content */
  align-items: center; /* 垂直居中 */
  padding: 10vw 0;
  text-align: center;
  background-repeat: repeat-y; /* 横向镜像重复 */
  background-size: 100% auto; /* 高度充满，宽度自适应 */
  background-position: top; /* 背景从中间开始 */
}

.flavor-list-container {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden; /* Hide overflowing content */
  background-image: url("../assets/black.jpg");
  background-size: 100% auto;
}

.flavor-list {
  display: flex;
  transition: scroll-behavior 0.3s ease; /* Smooth scrolling */
  overflow-x: scroll;
  scrollbar-width: none; /* Hide scrollbar for modern browsers */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
}

.flavor-list::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome/Safari */
}

.flavor {
  text-align: center;
  flex: 0 0 auto; /* Prevent items from shrinking */
  width: 30vw;
  margin: 0px 12.5px 15px 12.5px; /* Add spacing between items */
}

.flavor img {
  width: 25vw;
  height: auto;
  display: block;
  margin: 0 auto;
}

.prev-button,
.next-button {
  font-size: 8px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fca25d;
  color: #fff;
  border: 2px solid black;
  padding: 5px 10px;
  min-padding: 1vw;
  min-width: 3vw;
  min-height: 3vw;
  border-radius: 15px;
  min-border-radius: 1.3vw;
  cursor: pointer;
  z-index: 10;
  margin: 15px;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.name {
  display: inline-block;
  font-size: 1.5vw;
  padding: 1vw;
  color: black;
  border-radius: 4vw;
  margin: 0 auto;
}

@media (max-width: 500px) {
  .name {
    width: 30vw;
    padding: 0.5vw;
  }

  .flavor img {
    width: 38vw;
  }

  .flavor {
    width: 40vw;
  }

  .flavors {
    padding: 15vw 0;
    background-position: center; /* 背景从中间开始 */

  }

}

</style>
