<template>
  <div class="form-container">
    <button v-if="status==''" class="verify-btn">VERIFY</button>
    <button v-if="status=='ok'" class="verify-btn" style="background-color: green">VERIFIED</button>
    <button v-if="status=='error'" class="verify-btn" style="background-color: red">NOT VERIFIED</button>
    <p style="color: white;margin: 5px auto;width: 80%;line-height: 1.5">{{ msg }}</p>
    <input v-model=fw type="text" class="input-box" :readonly=readonly />
    <button class="submit-btn" @click="queryCode">SUBMIT</button>
  </div>
</template>

<script>

import {queryFwCode} from "@/api/agent";

export default {
  name: "FormPage",
  created() {
    this.getFw()
  },
  data() {
    return {
      fw: '',
      readonly: true,
      status: "",
      msg: "Thank you for choosing PLUME! Please enter your information below to register your product for warranty."
    };
  },
  mounted() {
    console.log(this.fw)
    if (this.fw != '') {
      this.queryCode()
    }
  },
  methods: {
    getFw() {
      const urlParams = new URLSearchParams(window.location.search);
      this.fw = urlParams.get('fw');
      if (this.fw == null) {
        this.readonly = false
        this.fw = ''
      }
    },
    queryCode() {
      if (!this.fw) {
        alert("Please enter your information below to register your product for warranty.");
        return;
      }
      queryFwCode(this.fw).then(response => {
            switch (response.data.level) {
              case 'error':
                this.status = 'error'
                this.msg = 'THIS IS AN NOT AUTHENTIC PLUME PRODUCT.\n' +
                    'THIS PRODUCT HAS BEEN REGISTERED TOO MANY TIMES.\n' +
                    'PLEASE RE-ENTER CODE TO TRY AGAIN.'
                break
              case 'ok', 'notice':
                this.status = 'ok'
                this.msg = "THANK YOU FOR CHOOSING PLUME!\n" +
                    "THIS IS AN AUTHENTIC PLUME PRODUCT.\n" +
                    "SMOKE PLUME. LOVE PLUME. PLÜME"
                break
            }
          }
      )
    }

  }
};
</script>
<style scoped>
.form-container {
  font-size: 2vw;
  text-align: center;
  align-content: center;
  padding: 50px 0;
  background-image: url("../assets/black.jpg");
  background-size: cover;
}

.verify-btn,
.submit-btn {
  font-family: ComicLemon;
  font-weight: 600;
  font-size: 2vw;
  padding: 12px 32px;
  border: none;
  cursor: pointer;
  border-radius: 16px;
}

.verify-btn {
  background-color: #fca25d;
  color: white;
  margin-bottom: 1rem;
}

.submit-btn {
  background-color: #70d382;
  color: white;
}

.input-box {
  font-weight: bold;
  display: block;
  margin: 2rem auto;
  padding: 10px 28px;
  width: 80%;
  font-size: 2vw;
  letter-spacing: 2px;
  text-align: center; /* 水平居中 */

}
</style>
