import { createRouter, createWebHistory } from "vue-router";
import Home from "@/page/Home.vue";
import FlavorDetail from "@/page/FlavorDetail.vue";

const routes = [
    { path: "/", name: "HomePage", component: Home },
    { path: "/index.html", name: "IndexPage", component: Home },
    { path: "/detail/:flavor", name: "FlavorDetail", component: FlavorDetail },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
