<template>
  <div>
    <HeaderPage/>
    <FormPage style="min-height: 50vh"/>
    <FlavorBanner text="TRY OUR OTHER FLAVORS" backgroundColor="#3498db"/>
    <FlavorsPage/>
    <FlavorBanner text="" backgroundColor="#3498db"/>
    <footer>
      <button class="footer-btn smoke">SMOKE PLUME</button>
      <img src="@/assets/plume.jpg" alt="Plume Logo" class="logo"/>
      <button class="footer-btn love">LOVE PLUME</button>
    </footer>
  </div>
</template>

<script>
import HeaderPage from "../components/Header.vue";
import FormPage from "../components/Form.vue";
import FlavorsPage from "../components/Flavors.vue";
import FlavorBanner from "../components/FlavorBanner.vue"; // 引入组件


export default {
  name: "HomePage",
  components: {
    HeaderPage,
    FormPage,
    FlavorsPage,
    FlavorBanner,
  },
};
</script>

<style>
/* 全局样式 */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

footer {
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  margin-bottom: 0;
}

.footer-btn {
  font-family: ComicLemon;
  padding: 0.5rem;
  font-size: 2vw;
  border: 2px solid black;
  cursor: pointer;
  border-radius: 4rem;
}

.footer-btn.smoke {
  background-color: #d1f4f2;
}

.footer-btn.love {
  background-color: #f7d7f7;
}

.logo {
  width: 29%;
}
</style>
