<template>
  <div class="flavor-banner">
    <div class="flavor-line"></div>
    <div v-if="text" class="flavor-button"  :style="{backgroundColor: backgroundColor}">
      {{ text }}
    </div>
    <div class="flavor-line"></div>
  </div>
</template>

<script>
export default {
  name: "FlavorBanner",
  props: {
    text: {
      type: String,
      default: "", // 默认文案
    },
    backgroundColor: {
      type: String,
      default: "#FFFFFF", // 设置默认背景颜色为白色
    },
  },
};
</script>

<style scoped>
.flavor-banner {
  font-size: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: yellow; /* 设置背景色 */
  height:15px; /* 设置区域高度 */
}

.flavor-line {
  flex: 1;
  height: 4px;
  background: black; /* 条纹渐变 */
}

.flavor-button {
  border: solid 2px black;
  padding: 10px 16px;
  background-color: #6ba8e5; /* 椭圆的背景色 */
  color: white;
  font-weight: bold;
  border-radius: 50px; /* 椭圆形 */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3); /* 添加轻微阴影 */
  text-align: center;
  white-space: nowrap; /* 防止文字换行 */
  z-index: 1000;
}
</style>
