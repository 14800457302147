<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
html {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
}

#app {
  width: 100%;
  font-family: ComicLemon;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*max-width: 450px;*/
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column; /* 使内容和 footer 垂直排列 */
  min-height: 100vh; /* 确保容器至少占满视口高度 */
}


@font-face {
  font-family: ComicLemon;
  src: url("font/ComicLemon.otf")
}

@font-face {
  font-family: HelveticaNeue;
  src: url("font/HelveticaNeue.ttc")
}


* {
  box-sizing: border-box;
}

</style>
