import {ElLoading} from "element-plus";
/**
 * 打开次数
 */
let needLoadingRequestCount = 0;
let loading

/**
 * 关闭loading
 */
function endLoading() {
    needLoadingRequestCount = 0;
    loading.close();
}

/**
 * 开启loading
 */
function showLoading() {
    needLoadingRequestCount++;
    loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        background: 'rgba(0, 0, 0, 0)'
    })
}

/**
 * 取消loading
 */
function hideLoading() {
    //if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--;
    if (needLoadingRequestCount <= 0) {
        endLoading()
    }
}

export { showLoading, hideLoading }
