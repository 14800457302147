<template>
  <div class="flavors-page">
    <HeaderPage/>
    <div style="display: flex; align-items: center; justify-content: flex-end; margin: 10px">
      <div class="go_home defaultColor" style="margin-right: 20px" @click="goHome">HOME</div>
      <div class="go_home defaultColor"  @click="goHome">AUTHENTICATE</div>
    </div>
    <div class="top-section">
      <div class="info" :style="{backgroundImage:`url(${detail.fruit})` }">
        <div class="flavor-div defaultColor">{{ name }}</div>
        <img :src="detail.image" class="device-image"/>
        <div :style="{backgroundColor: detail.bg }" class="type-div">INDICA</div>
      </div>
    </div>
    <FlavorBanner :text="name" :backgroundColor="detail.bg"/>

    <div class="info" style="padding: 50px 20px" :style="{backgroundImage:`url(${detail.bgImg})` }">
      <div class="feature">
        <div
            v-for="(item, index) in detail.leftFeature"
            :key="index"
            class="type-div" :style="{ backgroundColor: index % 2 === 0 ? detail.bg : 'rgb(75, 163, 155)' }">
          {{ item }}
        </div>
      </div>
      <div id="label" style="position: relative;">
        <img  style="width: 70%" :src="detail.simple" class="device-image"/>
        <div class="label">
          <div style="border-bottom: 2px solid black;color: white;border-radius: 0.8vw 0.8vw 0 0" :style="{ backgroundColor:detail.bg}">
            {{ detail.label }}
          </div>
          <img :src="detail.label_img"
               style="padding:0 1.8vw;border-radius: 0 0 2.8vw 2.8vw;width: 100%;height: auto"/>
        </div>

      </div>
      <div class="feature">
        <div
            v-for="(item, index) in detail.rightFeature"
            :key="index"
            class="type-div" :style="{ backgroundColor: index % 2 !== 0 ? detail.bg : 'rgb(75, 163, 155)' }">
          {{ item }}
        </div>
      </div>
    </div>
    <FlavorBanner text="TRY OUR OTHER FLAVORS" backgroundColor="#3498db"/>
    <FlavorsPage :fruit="detail.fruit"/>
    <FlavorBanner text="" backgroundColor="#3498db"/>
    <footer>
      <button class="footer-btn smoke">SMOKE PLUME</button>
      <img src="@/assets/plume.jpg" alt="Plume Logo" class="logo"/>
      <button class="footer-btn love">LOVE PLUME</button>
    </footer>

  </div>
</template>

<script>

import HeaderPage from "@/components/Header";
import FormPage from "@/components/Form";
import FlavorsPage from "@/components/Flavors";
import FlavorBanner from "@/components/FlavorBanner";

export default {
  name: "FlavorDetail",
  components: {
    HeaderPage,
    FormPage,
    FlavorsPage,
    FlavorBanner,
  },
  created() {
    this.flavorName();
  },
  data() {
    return {
      name: "",
      detail: {
        leftFeature: [],
        rightFeature: [],
      },

      flavors: {
        "TROPICAL LEMONADE": {
          fruit: require("@/assets/1fr.jpg"),
          name: "TROPICAL LEMONADE",
          image: require("@/assets/1.png"),
          bg: "rgb(122,195,226)",
          bgImg: require("@/assets/1_bg.jpg"),
          simple: require("@/assets/1s.png"),
          label_img: require("@/assets/1x.jpg"),
          label: "SATIVA",
          features: ["TANGY", "LEMON", "ICY", "GAS"]
        },
        "LUSCIOUS LYCHEE": {
          name: "LUSCIOUS LYCHEE",
          image: require("@/assets/2.png"),
          bg: "rgb(220,157,140)",
          bgImg: require("@/assets/2_bg.jpg"),
          fruit: require("@/assets/2fr.jpg"),
          simple: require("@/assets/2s.png"),
          label_img: require("@/assets/2x.jpg"),
          label: "SATIVA",
          features: ["MOUTH WATERING", "LYCHEE", "SWEET", "CANDY"]
        },
        "DIRTY SPRITE": {
          name: "DIRTY SPRITE",
          image: require("@/assets/3.png"),
          bg: "rgb(194,191,221)",
          fruit: require("@/assets/3fr.jpg"),
          bgImg: require("@/assets/3_bg.jpg"),
          simple: require("@/assets/3s.png"),
          label: "INDICA",
          label_img: require("@/assets/3x.jpg"),
          features: ["TANGY", "LIME", "APPETIZING", "GRAPE"]
        },
        "STRAWBERRY COUGH": {
          name: "STRAWBERRY COUGH",
          image: require("@/assets/4.png"),
          bg: "rgb(167,61,62)",
          bgImg: require("@/assets/4_bg.jpg"),
          fruit: require("@/assets/4fr.jpg"),
          simple: require("@/assets/4s.png"),
          label_img: require("@/assets/4x.jpg"),
          label: "SATIVA",
          features: ["POTENT", "STRAWBERRY", "SATISFYING", "CANDY"]
        },
        "BLACKBERRY OCTANE": {
          name: "BLACKBERRY OCTANE",
          image: require("@/assets/5.png"),
          bg: "rgb(96,83,142)",
          bgImg: require("@/assets/5_bg.jpg"),
          fruit: require("@/assets/5fr.jpg"),
          simple: require("@/assets/5s.png"),
          label_img: require("@/assets/5x.jpg"),
          label: "INDICA",
          features: ["BOTANICAL", "BERRY", "GAS", "JUICY"]
        },
        "PINEAPPLE EXPRESS": {
          name: "PINEAPPLE EXPRESS",
          image: require("@/assets/6.png"),
          bg: "rgb(252,249,202)",
          bgImg: require("@/assets/6_bg.jpg"),
          fruit: require("@/assets/6fr.jpg"),
          simple: require("@/assets/6s.png"),
          label_img: require("@/assets/6x.jpg"),
          label: "HYBRID",
          features: ["SOUR", "PINEAPPLE", "DELIGHTFUL", "TANGY"]
        },
        "DEW NECTAR": {
          name: "DEW NECTAR",
          image: require("@/assets/7.png"),
          bg: "rgb(155,198,107)",
          bgImg: require("@/assets/7_bg.jpg"),
          fruit: require("@/assets/7fr.jpg"),
          simple: require("@/assets/7s.png"),
          label_img: require("@/assets/7x.jpg"),
          label: "INDICA",
          features: ["SYRUP", "HONEYDEW", "JUICY", "CRISP"]
        },
        "MANGO MINTALITY": {
          name: "MANGO MINTALITY",
          image: require("@/assets/8.png"),
          bg: "rgb(119,188,93)",
          bgImg: require("@/assets/8_bg.jpg"),
          fruit: require("@/assets/8fr.jpg"),
          simple: require("@/assets/8s.png"),
          label_img: require("@/assets/8x.jpg"),
          label: "INDICA",
          features: ["SWEET", "MANGO", "MINT", "POTENT"]
        },
        "CAPRI SUN": {
          name: "CAPRI SUN",
          image: require("@/assets/9.png"),
          bg: "rgb(196,238,236)",
          bgImg: require("@/assets/9_bg.png"),
          fruit: require("@/assets/9fr.jpg"),
          simple: require("@/assets/9s.png"),
          label_img: require("@/assets/9x.jpg"),
          label: "HYBRID",
          features: ["SWEET", "KIWI", "TANGY", "JUICY"]
        },
        "PLUM PARADISE": {
          name: "PLUM PARADISE",
          image: require("@/assets/10.png"),
          bg: "rgb(139,56,84)",
          bgImg: require("@/assets/10_bg.jpg"),
          fruit: require("@/assets/10fr.jpg"),
          simple: require("@/assets/10s.png"),
          label: "SATIVA",
          label_img: require("@/assets/10x.jpg"),
          features: ["TANGY", "PLUM", "SOUR", "GAS"]
        },
      }
    };

  },
  methods: {
    goHome() {
      this.$router.push('/'); // 跳转到首页
    },
    flavorName() {
      this.name = this.$route.params.flavor.replace("-", " ");
      this.detail = this.flavors[this.name];
      let arr = this.detail.features
      const mid = Math.ceil(arr.length / 2); // 计算中间索引，向上取整
      this.detail.leftFeature = arr.slice(0, mid); // 截取前半部分
      this.detail.rightFeature = arr.slice(mid);  // 截取后半部分
    },
  },
};
</script>

<style scoped>
.flavors-page {
  text-align: center;
}

.top-section {
  padding: 10vw 0 10vw 0;
  background-image: url("../assets/black.jpg");
  background-size: contain;
}

.info {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between; /* 将子元素均匀分布 */
  background-size: 100% auto;
  background-position: center;
}

.feature {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-size: contain;
}

.device-image {
  width: 25%;
}

.flavor-div,
.type-div {
  margin: 4px auto;
  padding: 5px 6px;
  font-size: 2vw;
  border-radius: 4vw;
  border: 1px solid black;
  max-width: 33vw;
  min-width: 26vw;
}

.go_home {
  padding: 1vw;
  font-size: 2vw;
  color: white;
  border-radius: 4vw;
  border: 1px solid black;
  display: inline-block;
}

.flavor-div {
  background-color: #64c964;
  color: #fff;
}

.type-div {
  background-color: #a6d5a6;
  color: #fff;
}

.defaultColor {
  background-color: rgb(75, 163, 155);
}

.feature {
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* 使内容和 footer 垂直排列 */
}

.label {
  background-color: white;
  font-size: 2vw;
  padding: 0;
  position: absolute;
  left: 50%; /* 将元素移动到水平中心 */
  transform: translateX(-50%); /* 修正偏移，实现精确居中 */
  border: 2px black solid;
  border-radius: 1vw 1vw 2.8vw 2.8vw;
  text-align: center; /* 确保内部文字居中 */
  bottom: 50px;
}

.footer-btn
.logo {
  display: inline-block;
  font-size: 1vw;
}

@media (max-width: 500px) {
  .top-section {
    padding: 15vw 0;
  }
  .label {
    bottom: 10px;
  }
}
</style>
