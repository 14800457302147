<template>
  <div class="header">
    WARNING: This product produces amazingly loud plumes of smoke but also
    secretly amazing taste.
  </div>
</template>
<script>
export default {
  name: "HeaderPage",
  data() {
    return {};
  },
};
</script>
<style scoped>
.header {
  min-height: 38px;
  background-color: #fff;
  font-weight: bold;
  font-family: HelveticaNeue;
  display: flex;
  justify-content: space-around;
  align-items: center; /* 垂直居中 */
  font-size: 28px;
}

@media (max-width: 500px) {
  .header {
    font-size: 14px;
  }
}
</style>
