import axios from 'axios'
import { ElMessageBox, ElMessage } from 'element-plus'
import { showLoading, hideLoading } from '@/utils/loading'

// create an axios instance
const service = axios.create({
  baseURL: 'https://hongdun.1-2315.com', // url = base url + request url
  // baseURL: 'http://127.0.1:8000/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
      showLoading(true)
      return config
    },
    error => {
      // do something with request error
      hideLoading()
      console.log(error) // for debug
      return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
      hideLoading()
      const code = response.data.code
      if (code === undefined) {
        return response
      }
      if (code === 401) {
        if (location.href.indexOf('login') !== -1) {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        } else {
          ElMessageBox.confirm(
              '登录状态已过期，您可以继续留在该页面，或者重新登录',
              '系统提示',
              {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning'
              }
          ).then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        }
      } else if (code !== 200) {
        ElMessage({
          message: response.data.msg,
          type: 'error'
        })
        return Promise.reject('error')
      } else {
        return response.data
      }
    },
    error => {
      hideLoading()
      if (error.message === 'Network Error') {
        ElMessage({
          message: '服务器连接异常，请检查服务器！',
          type: 'error',
          duration: 5 * 1000
        })
        return
      }
      console.log('err' + error) // for debug

      ElMessage({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })

      return Promise.reject(error)
    }
)

export default service
